<template>
  <div @mouseover="topCenterMouseover" @mouseout="topCenterMouseout">
    <template v-for="item in contentList">
      <div
        v-show="item.visible"
        :key="item.id"
        class="drag-content-list"
        :style="
          'z-index:' +
            item.zIndex +
            ';top:' +
            (item.mouseY - 20) +
            'px' +
            (item.mouseX ? `;left:${item.mouseX - 20}px;` : ';right:130px;') +
            `min-height:${item.id == 4 ? '85px' : '100px'}`
        "
      >
        <div
          @mousedown="
            e => {
              mousedown(e, item)
            }
          "
          class="drag-content-title"
        >
          <span>{{ item.title }}</span>
          <div class="pushpin-icon" @mousemove="noDrag = true" @mouseout="noDrag = false">
            <a-icon v-if="item.id == 3" @click="getPageList" type="redo" style="font-size:16px" />
            <pushpin
              style="margin-left:6px;"
              @mouseover="item.pushpinColor = color"
              @mouseout="item.pushpinColor = '#4a4a4a'"
              theme="outline"
              size="16"
              @click="item.isPushpin = !item.isPushpin"
              :fill="item.isPushpin ? color : item.pushpinColor"
              :strokeWidth="3"
            />
            <a-icon
              v-if="!item.allHeight && item.id != 1 && item.id != 101"
              @click="changeAllheight(item)"
              style="margin-left:6px;"
              type="column-height"
            />
            <a-icon
              v-else-if="item.allHeight && item.id != 1 && item.id != 101"
              @click="changeAllheight(item)"
              style="margin-left:6px;"
              type="vertical-align-middle"
            />
            <a-icon style="margin-left:6px;" @click="closeContentList(item)" type="close" />
          </div>
        </div>
        <div
          class="drag-content-show"
          :style="item.allHeight ? { height: bottomHeight - 100 + 'px' } : ''"
          @click="topItem(item)"
        >
          <div v-if="item.id == 4">
            <div style="padding: 12px 18px;" v-if="showAnnotationList.length && checkPermission('local:view')">
              <a-checkbox-group
                v-model="checkedToStages"
                :options="[
                  { label: '翻译环节', value: '翻译环节' },
                  { label: '制作环节', value: '制作环节' }
                ]"
              />
            </div>
            <div
              class="local-show"
              :style="item.allHeight ? { maxHeight: bottomHeight - 100 + 'px !important' } : 'max-height: 400px;'"
            >
              <div v-if="showAnnotationList.length && checkPermission('local:view')" class="open-all-annotation-list">
                <a
                  v-if="
                    leftAnnotationList.some(page => page.annotationList.some(anItem => !anItem.selected)) ||
                      rightAnnotationList.some(page => page.annotationList.some(anItem => !anItem.selected))
                  "
                  @click.stop="openAnnotationList"
                  >全展开/收起</a
                >
                <a v-else @click.stop="closeAnnotationList">全展开/收起</a>
              </div>
              <a-collapse
                @mousemove="noDrag = true"
                @mouseout="noDrag = false"
                v-if="showAnnotationList.length && checkPermission('local:view')"
                v-model="showAnnotationOpenKey"
                :bordered="false"
              >
                <template #expandIcon="props">
                  <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                </template>
                <a-collapse-panel
                  v-for="(page, pageIdx) in showAnnotationList.filter(
                    page =>
                      page.annotationList &&
                      page.annotationList.filter(annoItem =>
                        checkedToStages.some(toStage => toStage == annoItem.toStage)
                      ).length
                  )"
                  :key="pageIdx + ''"
                  :style="customStyle"
                >
                  <div slot="header">
                    <span>{{ `第${pageListData.findIndex(p => p.id == page.pageId) + 1}页` }}</span>
                    <span style="margin-left:20px">{{ `共${page.annotationList.length}处` }}</span>
                  </div>
                  <div
                    :class="
                      selectId === annoItem.id
                        ? 'local-content-list-show local-content-list-show-selected'
                        : 'local-content-list-show'
                    "
                    @click="toLocal(annoItem)"
                    v-for="annoItem in page.annotationList.filter(annoItem =>
                      checkedToStages.some(toStage => toStage == annoItem.toStage)
                    )"
                    :key="annoItem.id"
                    :id="'local-list-item-' + annoItem.id"
                  >
                    <div class="local-content-list-num-two">
                      <div>{{ annoItem.sequenceNum }}</div>
                    </div>
                    <div class="local-content-list" v-if="annoItem.commentData && annoItem.commentData.length">
                      <div style="padding: 5px 0 5px 0;">
                        <div>{{ annoItem.toStage }}</div>
<!--                         <div v-else style="display:flex">
                          <div style="font-size:16px;height:100%">
                            To:
                          </div>
                          <a-radio-group style="margin-left:10px;" v-model="annoItem.toStage">
                            <a-radio value="翻译环节">
                              翻译环节
                            </a-radio>
                            <a-radio value="制作环节">
                              制作环节
                            </a-radio>
                          </a-radio-group>
                        </div> -->
                      </div>
                      <div class="local-content-item" v-for="(i, idx) in annoItem.commentData" :key="'anno' + idx">
                        <div class="local-content-item-top">
                          <div class="local-content-item-top-left">
                            <img :src="i.avatar" alt="" />
                          </div>
                          <div class="local-content-item-top-right">
                            <div class="local-content-item-top-right-user">
                              <span>{{ i.userName }}</span>
                              <span :class="selectId == annoItem.id ? 'action-span-select' : 'action-span'">
                                <a-dropdown :trigger="['click']" transitionName="">
                                  <a-icon
                                    @click.stop
                                    type="more"
                                    v-if="
                                      (checkPermission('local:dele') ||
                                        checkPermission('local:edit') ||
                                        i.userId == userInfo.id) &&
                                        changeAll
                                    "
                                  />
                                  <a-menu slot="overlay">
                                    <a-menu-item
                                      v-if="(checkPermission('local:edit') || i.userId == userInfo.id) && changeAll"
                                      @click="editLocalContent(i, annoItem)"
                                      key="0"
                                    >
                                      编辑
                                    </a-menu-item>
                                    <a-menu-item
                                      v-if="(checkPermission('local:dele') || i.userId == userInfo.id) && changeAll"
                                      key="1"
                                      @click="deletLocal(annoItem,i)"
                                    >
                                      删除
                                    </a-menu-item>
                                  </a-menu>
                                </a-dropdown>
                              </span>
                            </div>
                            <div>
                              {{ i.createTime }}
                            </div>
                          </div>
                        </div>
                        <div @click.stop="toLocal(annoItem)" class="local-content-item-bottom">
                          <span v-if="!i.editble" v-html="i.content"></span>
                          <div v-else style="padding-right:5px;padding-top:3px;">
                            <!--                           <a-textarea
                            @click.stop
                            v-model="i.content"
                            :disabled="!changeAll"
                            placeholder="ALT+回车换行，回车发送"
                            @keydown.enter="
                              e => {
                                localEnter(e, annoItem, i)
                              }
                            "
                            :auto-size="{ minRows: 1, maxRows: 10 }"
                          /> -->
                            <j-editor
                              :ref="'localInput-local-' + annoItem.id"
                              @postValue="localEnter(null, annoItem, i)"
                              :placeholder="'ALT+回车换行，回车发送'"
                              v-model="i.content"
                              :maxWidth="'50px'"
                              :toolbar="[]"
                            ></j-editor>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          selectId === annoItem.id &&
                            annoItem.commentData &&
                            annoItem.commentData.every(i => !i.editble)
                        "
                        style="padding-top:5px"
                      >
                        <j-editor
                          :ref="'localInput-local-send'"
                          @postValue="pushLoacal(annoItem)"
                          :placeholder="'ALT+回车换行，回车发送'"
                          v-model="postValue"
                          :maxWidth="'50px'"
                          :toolbar="[]"
                        ></j-editor>
                      </div>
                    </div>
                  </div>
                </a-collapse-panel>
              </a-collapse>
              <div v-else style="padding-top:15px;padding-bottom:15px;">
                <a-empty />
              </div>
            </div>
          </div>
          <div
            v-if="
              item.id == 3 &&
                (showOptions.leftOptions.length == 1 ||
                  (showOptions.leftOptions.length > 1 && !showOptions.leftOptions[1].checked))
            "
            :style="item.allHeight ? { height: bottomHeight - 100 + 'px' } : ''"
            class="text-list-show"
          >
            <div class="text-list-top">
              <span class="text-list-radio">
                <span
                  @click="textView = 'originalText'"
                  :class="textView == 'originalText' ? 'text-list-radio-selected' : 'text-list-radio1'"
                >
                  <span :style="textView == 'originalText' ? 'border:none' : ''">原文</span>
                </span>
                <span
                  @click="textView = 'translateText'"
                  :class="textView == 'translateText' ? 'text-list-radio-selected' : 'text-list-radio2'"
                >
                  <span :style="textView == 'revisionText' ? 'border:none' : ''">译文</span>
                </span>
              </span>
              <div>
                <span v-if="textView == 'originalText'">
                  共{{ getTextAreaNum('originalText') }}个文本，{{ getTextNum('originalText') }}个字
                </span>
                <span v-else> 共{{ getTextAreaNum('showText') }}个文本，{{ getTextNum('showText') }}个字 </span>
              </div>
            </div>
            <div class="text-list-center" @mousemove="noDrag = true" @mouseout="noDrag = false">
              <a-input-search
                v-model="textInputValue"
                placeholder="请输入内容"
                style="width: 100%"
                @search="textSearch"
              >
              </a-input-search>
            </div>
            <div
              class="text-list-bottom"
              id="text-list-modal"
              :style="item.allHeight ? { maxHeight: bottomHeight - 210 + 'px !important' } : 'max-height: 400px;'"
            >
              <div v-if="pageListData.length" class="open-all-annotation-list">
                <a v-if="textDefaultKey.length != textList.length" @click.stop="openTextList">全展开/收起</a>
                <a v-else @click.stop="closeTextList">全展开/收起</a>
              </div>
              <a-collapse
                @mousemove="noDrag = true"
                @mouseout="noDrag = false"
                v-if="pageListData.length"
                @change="textDefaultKeyChange"
                v-model="textDefaultKey"
                :bordered="false"
              >
                <template #expandIcon="props">
                  <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                </template>
                <a-collapse-panel v-for="(page, pageIdx) in textList" :key="page.pageId + ''" :style="customStyle">
                  <div slot="header">
                    <span>{{ `第${page.pageSequenceNum}页` }}</span>
                    <span style="margin-left:20px">{{
                      `共${page.textAreaCount}个文本，${
                        textView == 'originalText' ? page.originWordCount : page.translationWordCount
                      }个字`
                    }}</span>
                  </div>
                  <div
                    :class="
                      selectTextId === item.id
                        ? 'local-content-list-show local-content-list-show-selected'
                        : 'local-content-list-show'
                    "
                    @click="toText(page, item, page.pageSequenceNum - 1)"
                    v-for="item in page.textList"
                    :key="item.id"
                    :id="`textList-${item.id}`"
                  >
                    <div class="local-content-list-num" style="padding-top:7px">
                      <div>{{ item.textAreaSequenceNum }}</div>
                    </div>
                    <div class="local-content-list">
                      <span v-if="textView != 'translateText'" class="space-text"
                        >{{ item.originText
                        }}<a-icon
                          v-if="item.originText"
                          @mouseup.stop="copyMessage"
                          v-copy="item.originText"
                          type="copy"
                      /></span>
                      <template v-else>
                        <div>
                          <div class="space-text" v-if="item.translationText"
                          >{{
                              item.phoneticList.length
                                ? item.translationText.slice(0, 3) + item.phoneticList.map(p => p.text).join('')
                                : item.translationText
                            }}<a-icon
                              v-copy="
                                item.phoneticList.length
                                  ? item.phoneticList.map(p => p.text).join('')
                                  : item.translationText.slice(3, item.translationText.length)
                              "
                              @mouseup.stop="copyMessage"
                              type="copy"
                            />
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </div>
          <div
            v-if="
              item.id == 5 &&
                (showOptions.leftOptions.length == 1 ||
                  (showOptions.leftOptions.length > 1 && !showOptions.leftOptions[1].checked))
            "
            class="text-list-show"
          >
            <div class="text-list-top"></div>
            <div class="text-list-center" style="padding-top:0" @mousemove="noDrag = true" @mouseout="noDrag = false">
              <a-row>
                <a-col :span="5">
                  <div style="text-align:right;margin-right:2px;">阶段</div>
                </a-col>
                <a-col :span="19">
                  <div class="revision-action" style="height: 40px;display: flex;">
                    <!--                     <a-radio-group v-model="revisionType" @change="changeRevisionList">
                      <a-radio :value="1">
                        校对
                      </a-radio>
                      <a-radio :value="2">
                        终审
                      </a-radio>
                    </a-radio-group> -->
                    <span class="text-list-top">
                      <span class="text-list-radio" @click="changeRevisionList" style="line-height:18px;">
                        <span
                          @click="revisionType = 1"
                          :class="revisionType == 1 ? 'text-list-radio-selected' : 'text-list-radio1'"
                        >
                          <span :style="revisionType == 1 ? 'border:none' : ''">校对</span>
                        </span>
                        <span
                          @click="revisionType = 2"
                          :class="revisionType == 2 ? 'text-list-radio-selected' : 'text-list-radio2'"
                        >
                          <span :style="revisionType == 2 ? 'border:none' : ''">终审</span>
                        </span>
                      </span>
                    </span>
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="5">
                  <div style="text-align:right;margin-right:2px;">文本框状态</div>
                </a-col>
                <a-col :span="19">
                  <div class="revision-action">
                    <a-checkbox-group v-model="errors" :options="errorOptions" @change="changeRevisionList" />
                  </div>
                </a-col>
              </a-row>
              <!--               <a-row>
                <a-col :span="5">
                  <div style="text-align:right;margin-right:2px;">驳回</div>
                </a-col>
                <a-col :span="19">
                  <div class="revision-action">
                    <a-radio-group v-model="isReject" @change="changeRevisionList">
                      <a-radio :value="2">
                        全部
                      </a-radio>
                      <a-radio :value="1">
                        是
                      </a-radio>
                      <a-radio :value="0">
                        否
                      </a-radio>
                    </a-radio-group>
                  </div>
                </a-col>
              </a-row> -->
              <!--               <a-row>
                <a-col :span="5">
                  <div style="text-align:right;margin-right:2px;">反馈</div>
                </a-col>
                <a-col :span="19">
                  <div class="revision-action">
                    <a-radio-group v-model="isFeedback" @change="changeRevisionList">
                      <a-radio :value="2">
                        全部
                      </a-radio>
                      <a-radio :value="1">
                        有
                      </a-radio>
                      <a-radio :value="0">
                        无
                      </a-radio>
                    </a-radio-group>
                  </div>
                </a-col>
              </a-row> -->
              <a-row>
                <a-col :span="5">
                  <div style="text-align:right;margin-right:2px;">修改状态</div>
                </a-col>
                <a-col :span="19">
                  <div class="revision-action">
                    <a-radio-group v-model="changeSignValue" @change="changeRevisionList">
                      <a-radio :value="0">
                        全部
                      </a-radio>
                      <a-radio :value="2">
                        已解决
                      </a-radio>
                      <a-radio :value="1">
                        未解决
                      </a-radio>
                    </a-radio-group>
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="5">
                  <div style="text-align:right;margin-right:2px;">修订记录</div>
                </a-col>
                <a-col :span="19">
                  <div class="revision-action">
                    <a-radio-group v-model="openEditHistory" @change="changeRevisionList">
                      <a-radio :value="1">
                        显示
                      </a-radio>
                      <a-radio :value="0">
                        不显示
                      </a-radio>
                    </a-radio-group>
                  </div>
                </a-col>
              </a-row>
            </div>
            <div
              class="text-list-bottom"
              id="revisiton-modal"
              :style="item.allHeight ? { maxHeight: bottomHeight - 370 + 'px !important' } : 'max-height: 400px;'"
            >
              <div v-if="revisionList && revisionList.length" class="open-all-annotation-list">
                <a v-if="reDefaultKey.length != revisionList.length" @click.stop="openReList">全展开/收起</a>
                <a v-else @click.stop="closeReList">全展开/收起</a>
              </div>
              <a-collapse
                @mousemove="noDrag = true"
                @mouseout="noDrag = false"
                v-if="revisionList && revisionList.length"
                v-model="reDefaultKey"
                @change="textDefaultKeyChange"
                :bordered="false"
              >
                <template #expandIcon="props">
                  <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                </template>
                <a-collapse-panel v-for="page in revisionList" :key="page.pageId + ''" :style="customStyle">
                  <div slot="header">
                    <span>{{ `第${page.sortOrderId}页` }}</span>
                    <span style="margin-left:20px">{{ `共${page.modifyHistoryList.length}个文本` }}</span>
                  </div>
                  <div
                    :class="
                      selectRevisitionId == item.textAreaId
                        ? 'local-content-list-show local-content-list-show-selected'
                        : 'local-content-list-show'
                    "
                    @click="toRevisiton(page, item, page.sortOrderId)"
                    v-for="item in page.modifyHistoryList"
                    :ref="item.textAreaId + 'revision'"
                    :id="item.textAreaId + 'revision'"
                    :key="item.textAreaId"
                  >
                    <div class="local-content-list-num">
                      <div>{{ item.textAreaSequenceNo }}</div>
                    </div>
                    <div class="local-content-list">
                      <div class="revisition-content" style="padding-bottom: 0px;">
                        <div style="display:flex;justify-content: space-between;">
                          <span
                            ><span v-if="item.characters || item.textAreaTypeModifyHistory">{{
                              revisionType == 1 ? '翻译：' : '校对：'
                            }}</span
                            >{{ item.textAreaTypeModifyHistoryPosition ? item.textAreaTypeModifyHistory : '' }}</span
                          >
                          <span v-if="revisionType == 1" style="display:flex;align-items: center;">
                            <!--                             <img :src="item.translationAvatar" alt="" />
                            <span style="margin-left:5px">{{ item.translationUserName }}</span> -->
                          </span>
                          <span v-else style="display:flex;align-items: center;">
                            <!--                             <img :src="item.revisionAvatar" alt="" />
                            <span style="margin-left:5px">{{ item.revisionUserName }}</span> -->
                          </span>
                          <span
                            v-if="item.changeSign && item.rejectComments && item.rejectComments.length && item.canShow"
                            @click="changeSign(item)"
                            :class="item.changeSign > 1 ? 'change-sign change-sign-over' : 'change-sign'"
                          >
                            <a-tooltip>
                              <template slot="title">
                                <span>{{ item.changeSign > 1 ? '标记为“未解决”' : '标记为“已解决”' }}</span></template
                              ><a-icon type="check-circle"
                            /></a-tooltip>
                          </span>
                        </div>
                        <div
                          :style="'color: rgba(0, 0, 0, 0.55);'"
                          v-if="item.characters"
                          class="space-text"
                          v-html="item.characters.map(c => c.oldCharacter).join('')"
                        ></div>
                      </div>
                      <div
                        class="revisition-content"
                        style="padding-bottom: 0px;"
                        v-if="item.characters || item.textAreaTypeModifyHistory"
                      >
                        <div style="display:flex;">
                          <span
                            >{{ revisionType == 1 ? '校对：' : '终审：'
                            }}{{ !item.textAreaTypeModifyHistoryPosition ? item.textAreaTypeModifyHistory : '' }}</span
                          >
                          <span v-if="revisionType == 1" style="display:flex;align-items: center;">
                            <!--                             <img :src="item.revisionAvatar" alt="" />
                            <span style="margin-left:5px">{{ item.revisionUserName }}</span> -->
                          </span>
                          <span v-else style="display:flex;align-items: center;">
                            <!--                             <img :src="item.auditAvatar" alt="" />
                            <span style="margin-left:5px">{{ item.auditUserName }}</span> -->
                          </span>
                        </div>
                        <div
                          v-if="item.characters"
                          class="space-text"
                          :style="
                            openEditHistory ? 'color: rgba(0, 0, 0, 0.55);padding-bottom: 5px;' : 'padding-bottom: 5px;'
                          "
                          v-html="item.characters.map(c => c.newCharacter).join('')"
                        ></div>
                      </div>
                      <div
                        class="revisition-content"
                        style="padding-bottom: 0px;"
                        v-if="checkPermission('errorsType:view') && item.injuryType >= 1 && item.injuryType <= 3"
                      >
                        <div class="revisition-content-error">
                          <span style="color:red;">错误类型</span>
                          <span :class="selectRevisitionId == item.textAreaId ? 'action-span-select' : 'action-span'">
                            <a-dropdown
                              :trigger="['click']"
                              transitionName=""
                              v-if="
                                (userInfo.id == item.remarkUserId ||
                                  checkPermission('errorsType:edit') ||
                                  checkPermission('errorsType:dele')) &&
                                  changeAll
                              "
                            >
                              <a-icon type="more" @click.stop />
                              <a-menu slot="overlay">
                                <a-menu-item
                                  v-if="checkPermission('errorsType:edit') || userInfo.id == item.remarkUserId"
                                  @click="editLocalContent(item)"
                                  key="0"
                                >
                                  编辑
                                </a-menu-item>
                                <a-menu-item
                                  v-if="checkPermission('errorsType:dele') || userInfo.id == item.remarkUserId"
                                  key="1"
                                  @click="deletRevisition(item)"
                                >
                                  删除
                                </a-menu-item>
                              </a-menu>
                            </a-dropdown>
                          </span>
                        </div>
                        <div v-if="!item.editble">
                          <span style="color:rgba(0,0,0,0.55)">
                            {{
                              item.injuryType == 3
                                ? '错翻。'
                                : item.injuryType == 2
                                ? '硬伤。'
                                : item.injuryType == 1
                                ? '软伤。'
                                : '无'
                            }}
                            {{ item.remark }}
                          </span>
                        </div>
                        <div v-else class="ocr-show-item-action-top">
                          <div class="ocr-show-item-action-radio">
                            <span @click.stop="changeInjuryType(item, 0)" :class="!item.injuryType ? 'error-span' : ''"
                              >无<a-icon v-if="!item.injuryType" type="check-circle" theme="filled"
                            /></span>
                            <span
                              @click.stop="changeInjuryType(item, 3)"
                              :class="item.injuryType == 3 ? 'error-span' : ''"
                              style="color:red;margin-left:3px"
                              >错<a-icon v-if="item.injuryType == 3" type="check-circle" theme="filled"
                            /></span>
                            <span
                              @click.stop="changeInjuryType(item, 2)"
                              :class="item.injuryType == 2 ? 'error-span' : ''"
                              style="color:red;margin-left:3px"
                              >硬<a-icon v-if="item.injuryType == 2" type="check-circle" theme="filled"
                            /></span>
                            <span
                              @click.stop="changeInjuryType(item, 1)"
                              :class="item.injuryType == 1 ? 'error-span' : ''"
                              style="color:red;margin-left:3px"
                              >软<a-icon v-if="item.injuryType == 1" type="check-circle" theme="filled"
                            /></span>
                          </div>
                          <div class="ocr-show-item-action-input" @click.stop>
                            <a-select
                              v-if="item.injuryType"
                              v-model="item.remark"
                              show-search
                              :placeholder="'请选择错误类型'"
                              style="width: 100%"
                            >
                              <a-select-option
                                v-for="errorItem in dictList"
                                :key="errorItem.id"
                                :value="errorItem.itemText"
                              >
                                {{ errorItem.itemText }}
                              </a-select-option>
                            </a-select>
                            <div class="ocr-show-item-action-input-button">
                              <a-button size="small" @click="cancelErrorEdit(item)">
                                取消
                              </a-button>
                              <a-button
                                style="margin-left:10px;"
                                type="primary"
                                size="small"
                                @click="changeError(item, page)"
                              >
                                保存
                              </a-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="revisition-content"
                        style="padding-bottom:0px"
                        v-if="isReject && checkPermission('reject:view')"
                      >
                        <div v-if="item.rejectComments && item.rejectComments.length" style="color:rgba(0,0,0,0.65)">
                          驳回理由：
                        </div>
                        <div v-for="rejectItem in item.rejectComments" :key="rejectItem.id">
                          <div style="display:flex;align-items: center;color:rgba(0, 0, 0, 0.65);">
                            <div style="margin-right:5px;">
                              <img :src="rejectItem.avatar" alt="" />
                            </div>
                            <div style="flex:1;">
                              <div style="height:20px;font-size:12px" class="revisition-content-error">
                                <span>{{ rejectItem.username }}</span>
                                <span
                                  :class="selectRevisitionId == item.textAreaId ? 'action-span-select' : 'action-span'"
                                >
                                  <a-dropdown
                                    transitionName=""
                                    :trigger="['click']"
                                    v-if="
                                      (rejectItem.userId == userInfo.id ||
                                        checkPermission('reject:edit') ||
                                        checkPermission('reject:dele')) &&
                                        changeAll
                                    "
                                  >
                                    <a-icon type="more" @click.stop />
                                    <a-menu slot="overlay">
                                      <a-menu-item
                                        v-if="rejectItem.userId == userInfo.id || checkPermission('reject:edit')"
                                        @click="editLocalContentReject(rejectItem, item)"
                                        key="0"
                                      >
                                        编辑
                                      </a-menu-item>
                                      <a-menu-item
                                        v-if="rejectItem.userId == userInfo.id || checkPermission('reject:dele')"
                                        key="1"
                                        @click="deletReject(rejectItem, item)"
                                      >
                                        删除
                                      </a-menu-item>
                                    </a-menu>
                                  </a-dropdown>
                                </span>
                              </div>
                              <div style="height:18px;font-size:12px;line-height:18px">{{ rejectItem.createTime }}</div>
                            </div>
                          </div>
                          <div class="revision-content" style="padding-left:25px;padding-top:5px;padding-bottom:5px;">
                            <div
                              class="show-reject"
                              v-html="rejectItem.comment"
                              style="color:rgba(0, 0, 0, 0.65);"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="revisition-content"
                        style="color:rgba(0, 0, 0, 0.65);padding-bottom:0"
                        v-if="checkPermission('fk:view')"
                      >
                        <div
                          v-if="
                            (selectRevisitionId == item.textAreaId || (item.feedbacks && item.feedbacks.length)) &&
                              changeAll
                          "
                          style="padding-bottom:5px;font-weight:700;color:block"
                        >
                          反馈：
                        </div>
                        <!--                         <a-textarea
                          v-if="
                            !isFeedback &&
                              selectRevisitionId == item.textAreaId &&
                              changeAll &&
                              checkPermission('fk:edit')
                          "
                          @click.stop
                          :auto-size="{ minRows: 1, maxRows: 10 }"
                          v-model="item.feedBackValue"
                          placeholder="ALT+回车换行，回车发送"
                          @keydown.enter="
                            e => {
                              feedBackEnter(e, item)
                            }
                          "
                        /> -->
                        <j-editor
                          v-if="
                            !isFeedback &&
                              selectRevisitionId == item.textAreaId &&
                              changeAll &&
                              checkPermission('fk:edit')
                          "
                          :ref="'localInput-revisition-' + item.textAreaId"
                          @postValue="feedBackEnter(null, item)"
                          :placeholder="'ALT+回车换行，回车发送'"
                          v-model="item.feedBackValue"
                          :maxWidth="'50px'"
                          :toolbar="[]"
                        ></j-editor>
                        <div v-else-if="isFeedback">
                          <div v-for="feedbackItem in item.feedbacks" :key="feedbackItem.id">
                            <div style="display:flex;align-items: center;">
                              <div style="margin-right:5px;">
                                <img :src="feedbackItem.avatar" alt="" />
                              </div>
                              <div style="flex:1;">
                                <div style="height:18px;font-size:12px" class="revisition-content-error">
                                  <span>{{ feedbackItem.username }}</span>
                                  <span
                                    :class="
                                      selectRevisitionId == item.textAreaId ? 'action-span-select' : 'action-span'
                                    "
                                  >
                                    <a-dropdown
                                      :trigger="['click']"
                                      transitionName=""
                                      v-if="
                                        (checkPermission('fk:edit') ||
                                          checkPermission('fk:dele') ||
                                          feedbackItem.userId == userInfo.id) &&
                                          changeAll
                                      "
                                    >
                                      <a-icon type="more" @click.stop />
                                      <a-menu slot="overlay">
                                        <a-menu-item
                                          v-if="feedbackItem.userId == userInfo.id || checkPermission('fk:edit')"
                                          @click="editLocalContent(feedbackItem)"
                                          key="0"
                                        >
                                          编辑
                                        </a-menu-item>
                                        <a-menu-item
                                          v-if="feedbackItem.userId == userInfo.id || checkPermission('fk:dele')"
                                          key="1"
                                          @click="deletFeedback(feedbackItem)"
                                        >
                                          删除
                                        </a-menu-item>
                                      </a-menu>
                                    </a-dropdown>
                                  </span>
                                </div>
                                <div style="height:18px;font-size:12px;line-height:18px">
                                  {{ feedbackItem.createTime }}
                                </div>
                              </div>
                            </div>
                            <div class="revision-content" style="padding-left:25px;padding-top:5px;padding-bottom:5px;">
                              <span v-if="!feedbackItem.editble" v-html="feedbackItem.feedback"></span>
                              <!-- <a-textarea
                                v-else-if="feedbackItem.editble && selectRevisitionId == item.textAreaId"
                                :disabled="
                                  (!checkPermission('fk:edit') || !changeAll) && feedbackItem.userId != userInfo.id
                                "
                                :auto-size="{ minRows: 1, maxRows: 10 }"
                                @click.stop
                                v-model="feedbackItem.feedback"
                                placeholder="ALT+回车换行，回车发送"
                                @keydown.enter="
                                  e => {
                                    feedbackItemEditEnter(e, feedbackItem, item)
                                  }
                                "
                              /> -->
                              <j-editor
                                v-else-if="feedbackItem.editble && selectRevisitionId == item.textAreaId"
                                :ref="'localInput-feedback-' + item.textAreaId"
                                @postValue="feedbackItemEditEnter(null, feedbackItem, item)"
                                :placeholder="'ALT+回车换行，回车发送'"
                                v-model="feedbackItem.feedback"
                                :maxWidth="'50px'"
                                :toolbar="[]"
                              ></j-editor>
                            </div>
                          </div>
                          <div
                            style="padding-top:2px;max-width:265px"
                            v-if="item.feedbacks.every(feedbackItem => !feedbackItem.editble)"
                          >
                            <!-- 王坤说不可编辑阶段直接不显示 -->
                            <!--                             <a-textarea
                              v-if="selectRevisitionId == item.textAreaId && changeAll"
                              :auto-size="{ minRows: 1, maxRows: 10 }"
                              @click.stop
                              :disabled="!changeAll"
                              v-model="item.feedBackValue"
                              placeholder="ALT+回车换行，回车发送"
                              @keydown.enter="
                                e => {
                                  feedBackEnter(e, item)
                                }
                              "
                            /> -->
                            <j-editor
                              v-if="selectRevisitionId == item.textAreaId && changeAll"
                              :ref="'localInput-item-' + item.textAreaId"
                              @postValue="feedBackEnter(null, item)"
                              :placeholder="'ALT+回车换行，回车发送'"
                              v-model="item.feedBackValue"
                              :maxWidth="'50px'"
                              :toolbar="[]"
                            ></j-editor>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </div>
          <div v-if="item.id == 1" class="text-type">
            <div v-if="checkPermission('textType:view')">
              <a-radio-group
                @change="textTypeChange"
                :disabled="
                  !selectTextAreaData.length ||
                    !checkPermission('textType:edit') ||
                    (taskDetail.taskStatus != '可开始' && taskDetail.taskStatus != '驳回') ||
                    (isRejectStatus && selectTextAreaData.some(item => !item.isReject)) ||
                    !changeAll
                "
                name="radioGroup"
                :default-value="defualtTextTypeNo"
                v-model="defualtTextType"
              >
                <a-radio
                  style="margin-top:3px;"
                  v-for="item in textTypes"
                  :key="item.sequenceNo"
                  :value="item.sequenceNo"
                >
                  {{ item.usage }}{{ item.isBold ? '（加粗）' : '' }}
                </a-radio>
              </a-radio-group>
            </div>
            <div v-else style="height: 100px;isplay: flex;justify-content: center;align-items: center;">
              <span>无权限</span>
            </div>
          </div>
          <div
            v-if="
              item.id == 7 &&
                (showOptions.leftOptions.length == 1 ||
                  (showOptions.leftOptions.length > 1 && !showOptions.leftOptions[1].checked))
            "
            :style="item.allHeight ? { height: bottomHeight - 100 + 'px' } : ''"
          >
            <search-modal
              ref="searchModal"
              :allHeight="item.allHeight"
              :bottomHeight="bottomHeight"
              :authList="authList"
              :showSeachIndex="showSeachIndex"
              :showImgLeftListPostions="showImgLeftListPostions"
              :isRejectStatus="isRejectStatus"
              :taskDetail="taskDetail"
              :color="color"
              :changeAll="changeAll"
              :oldTextBatchStyle="oldTextBatchStyle"
              :pageListData="pageListData"
              @savePageList="savePageList"
              @changeSelectList="changeSelectList"
              @getSearchList="getSearchList"
              @textDefaultKeyChange="textDefaultKeyChange"
            />
          </div>
          <div
            v-if="item.id == 6"
            class="log-list"
            :style="item.allHeight ? { maxHeight: bottomHeight - 100 + 'px !important' } : 'max-height: 500px;'"
          >
            <a-timeline v-if="logList.length">
              <a-timeline-item v-for="item in logList" :key="item.id">
                <div class="log-list-item">
                  <div class="log-list-item-left">
                    <div class="log-list-item-left-left">
                      <img :src="item.avatar" alt="" />
                    </div>
                    <div class="log-list-item-left-right">
                      <div>{{ item.userName }}</div>
                      <div>{{ item.createTime }}</div>
                    </div>
                  </div>
                  <div class="log-list-item-right">【{{ item.actionDesc }}】{{ item.target }}</div>
                </div>
              </a-timeline-item>
            </a-timeline>
            <div v-else style="padding-top:15px;padding-bottom:15px;">
              <a-empty />
            </div>
          </div>
          <div v-if="item.id == 101" :style="backCss" class="global-form">
            <div class="global-item">
              <a-row style="width:100%">
                <a-col class="global-item" :span="6">
                  <a-tooltip>
                    <template slot="title">
                      横向
                    </template>
                    <div
                      @click="textLayoutChange(1)"
                      class="global-item-option"
                      @mouseover="color2 = color"
                      @mouseout="color2 = '#4a4a4a'"
                    >
                      <text-rotation-none
                        style="position:relative;top:2px;"
                        theme="outline"
                        size="18"
                        :fill="formData.textLayout == 1 ? color : color2"
                        :strokeWidth="4"
                      />
                    </div>
                  </a-tooltip>
                  <a-tooltip>
                    <template slot="title">
                      竖向
                    </template>
                    <div
                      @click="textLayoutChange(2)"
                      class="global-item-option"
                      style="margin-left:10px;"
                      @mouseover="color1 = color"
                      @mouseout="color1 = '#4a4a4a'"
                    >
                      <text-rotation-down
                        theme="outline"
                        size="18"
                        :fill="formData.textLayout == 2 ? color : color1"
                        :strokeWidth="4"
                      />
                    </div>
                  </a-tooltip>
                </a-col>
                <a-col :span="18">
                  <span style="text-align:center;line-height:32px;margin-right:15px">文本类型</span>
                  <a-select
                    style="width: 191px;font-size:14px;margin-top:15px"
                    show-search
                    :maxTagCount="1"
                    mode="multiple"
                    v-model="formData.textAreaTypeList"
                    placeholder="请选择文本类型"
                  >
                    <a-select-option v-for="item in textTypes" :key="item.sequenceNo">
                      {{ item.usage }}
                    </a-select-option>
                  </a-select>
                </a-col>
              </a-row>
            </div>
            <div class="global-item">
              <a-tooltip>
                <template slot="title">
                  字体
                </template>
                <a-select
                  dropdownClassName="global-select"
                  placeholder="请选择字体"
                  :defualt-value="formData.fontFamilyId || ''"
                  v-model="formData.fontFamilyId"
                  @change="fontFamilyChange"
                  style="width: 160px;font-size:14px"
                >
                  <a-select-option :value="item.id" v-for="item in fontFamilyList" :key="item.id">
                    {{ item.displayName }}
                  </a-select-option>
                </a-select>
              </a-tooltip>
              <a-tooltip>
                <template slot="title">
                  字体样式
                </template>
                <a-select
                  placeholder="字体样式"
                  :defualt-value="formData.styleName || ''"
                  v-model="formData.styleName"
                  :disabled="true"
                  style="width: 100px;font-size:14px;margin-left:10px;"
                >
                  <a-select-option :value="formData.styleName" :key="formData.styleName">
                    {{ formData.styleName }}
                  </a-select-option>
                </a-select>
              </a-tooltip>
              <a-tooltip>
                <template slot="title">
                  字号
                </template>
                <a-select
                  :filter-option="filterOption"
                  show-search
                  @search="fontChange"
                  placeholder="请选择字号"
                  v-model="formData.fontSize"
                  style="width: 80px;font-size:14px;margin-left:10px;"
                >
                  <a-select-option :value="item.value" v-for="item in fontSizes" :key="item.label">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-tooltip>
            </div>
            <div class="global-item">
              <a-tooltip>
                <template slot="title">
                  加粗
                </template>
                <div class="global-item-option" @click="boldChange">
                  <a-icon type="bold" :style="formData.isBold ? { color } : ''" />
                </div>
              </a-tooltip>
              <a-tooltip>
                <template slot="title">
                  斜体
                </template>
                <div class="global-item-option" @click="isInclineChange" style="margin-left:10px">
                  <a-icon type="italic" :style="formData.isIncline ? { color } : ''" />
                </div>
              </a-tooltip>
              <a-tooltip>
                <template slot="title">
                  字体颜色
                </template>
                <div class="global-item-option" @click="colorClick('FontColor')" style="margin-left:10px">
                  <div class="color-change">
                    <div>
                      <div class="color-change-text">
                        A
                      </div>
                      <div
                        class="color-change-bottom"
                        :style="{ background: formData.fontColor, border: '1px solid rgba(0,0,0,0.25)' }"
                      ></div>
                    </div>
                  </div>
                  <colorPicker
                    ref="colorPickerFontColor"
                    :defaultColor="formData.fontColor || ''"
                    v-model="formData.fontColor"
                  />
                </div>
              </a-tooltip>
              <a-tooltip>
                <template slot="title">
                  字体描边
                </template>
                <div class="global-item-option" @click="colorClick('FontStroke')" style="margin-left:10px">
                  <div class="color-change">
                    <div>
                      <div
                        class="color-change-stroke-text"
                        :style="`height:19px;line-height:18px;-webkit-text-stroke: 1px rgba(0,0,0,0.65)`"
                      >
                        A
                      </div>
                      <div
                        class="color-change-bottom-stroke"
                        :style="{ background: formData.strokeColor, border: '1px solid rgba(0,0,0,0.25)' }"
                      ></div>
                    </div>
                  </div>
                  <colorPicker
                    ref="colorPickerFontStroke"
                    :defaultColor="formData.strokeColor || ''"
                    v-model="formData.strokeColor"
                  />
                </div>
              </a-tooltip>
              <a-tooltip>
                <template slot="title">
                  描边宽度
                </template>
                <a-select
                  style="width: 70px;font-size:14px;margin-left:10px;"
                  show-search
                  v-model="formData.strokeWidth"
                  placeholder="请选择描边宽度"
                >
                  <a-select-option v-for="i in strokeWidths" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-tooltip>
              <a-tooltip style="position: relative;top: 1px;">
                <template slot="title">
                  对齐方式
                </template>
                <div style="display:flex">
                  <div class="global-item-option" :style="{ marginLeft: '10px' }">
                    <align-text-left
                      @mouseover="color11 = color"
                      @mouseout="color11 = '#4a4a4a'"
                      @click="textAlignChange('left')"
                      theme="outline"
                      size="19"
                      :fill="formData.textAlign == 'left' ? color : color11"
                      :strokeWidth="4"
                    />
                  </div>
                  <div class="global-item-option" :style="{ marginLeft: '10px' }">
                    <align-text-center
                      @mouseover="color12 = color"
                      @mouseout="color12 = '#4a4a4a'"
                      @click="textAlignChange('center')"
                      theme="outline"
                      size="19"
                      :fill="formData.textAlign == 'center' ? color : color12"
                      :strokeWidth="4"
                    />
                  </div>
                  <div class="global-item-option " :style="{ marginLeft: '10px' }">
                    <align-text-right
                      @mouseover="color13 = color"
                      @mouseout="color13 = '#4a4a4a'"
                      @click="textAlignChange('right')"
                      theme="outline"
                      size="19"
                      :fill="formData.textAlign == 'right' ? color : color13"
                      :strokeWidth="4"
                    />
                  </div>
                </div>
              </a-tooltip>
            </div>
            <div class="global-item" style="padding-top:0px;width:100%">
              <a-row style="width:100%">
                <!--                 <a-col :span="4">
                  <span class="global-item-title">预览</span>
                </a-col>
                <a-col :span="9" style="height:100%">
                  <div
                    class="global-item-show"
                    :style="{
                      writingMode: formData.textLayout == 2 ? 'vertical-rl' : 'horizontal-tb',
                      textOrientation: formData.textLayout == 2 ? 'upright' : 'initial',
                      textAlign: formData.textAlign
                    }"
                  >
                    <span :style="getTextStyle()"
                      ><span
                        class="stroke-span-child"
                        data-text="漫小崔"
                        :style="
                          'display:inline-block;white-space: pre-wrap;' +
                            `font-size:${formData.fontSize}px;` +
                            `-webkit-text-stroke: ${formData.strokeWidth * 2}px ${
                              formData.strokeColor
                            };user-select:none;`
                        "
                        >漫小崔</span
                      ><br /><span
                        class="stroke-span-child"
                        data-text="漫小崔"
                        :style="
                          'display:inline-block;white-space: pre-wrap;' +
                            `font-size:${formData.fontSize}px;` +
                            `-webkit-text-stroke: ${formData.strokeWidth * 2}px ${
                              formData.strokeColor
                            };user-select:none;`
                        "
                        >漫小崔</span
                      >
                    </span>
                  </div>
                </a-col> -->
                <a-col :span="12">
                  <span style="text-align:center;line-height:32px;margin-right:15px">字高</span>
                  <a-select
                    style="width: 100px;font-size:14px;margin-top:15px"
                    show-search
                    v-model="formData.textHeight"
                    placeholder="请选择字高"
                  >
                    <a-select-option v-for="i in scaleY" :key="i.value">
                      {{ i.label }}
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col :span="12">
                  <span style="text-align:center;line-height:32px;margin-right:15px">字宽</span>
                  <a-select
                    style="width: 100px;font-size:14px;margin-top:15px"
                    show-search
                    v-model="formData.textWidth"
                    placeholder="请选择字宽"
                  >
                    <a-select-option v-for="i in scaleX" :key="i.value">
                      {{ i.label }}
                    </a-select-option>
                  </a-select>
                </a-col>
              </a-row>
            </div>
            <div class="global-item" style="padding-bottom:20px;width:100%">
              <a-row style="width:100%">
                <span style="text-align:center;line-height:32px;margin-right:15px">行距</span>
                <a-select
                  style="width: 100px;font-size:14px;"
                  show-search
                  v-model="formData.lineSpacing"
                  placeholder="请选择行距"
                >
                  <a-select-option v-for="i in lineHeight" :key="i.value">
                    {{ i.label }}
                  </a-select-option>
                </a-select>
              </a-row>
            </div>
          </div>
          <div
            v-if="item.id == 101"
            style="display:flex;padding-right:25px;padding-top:15px;padding-bottom:15px;width:100%;justify-content: end;border-top: 1px solid rgba(0, 0, 0, 0.1);"
          >
            <a-button
              size="small"
              @click="closeStyle(item)"
              style="margin-right:15px;font-size:12px;padding-left:10px;padding-right:10px"
            >
              取消
            </a-button>
            <a-button
              size="small"
              :loading="gloabalBtnLoading"
              @click="saveStyle(item)"
              :style="
                gloabalBtnLoading
                  ? 'font-size:12px;padding-left:20px;padding-right:10px'
                  : 'font-size:12px;padding-left:10px;padding-right:10px'
              "
              type="primary"
            >
              确定
            </a-button>
          </div>
        </div>
      </div>
    </template>
    <errorModal
      ref="errorModal"
      :dictList="[]"
      @changeIsDele="changeIsDele"
      @postRejectValue="postRejectValue"
      :selectErrorType="4"
    />
    <playvideo-modal @changeVideoWidth="changeVideoWidth" ref="videoModal" :width="imagePlayWidth" />
  </div>
</template>

<script>
import {
  TextRotationNone,
  TextRotationDown,
  TextBold,
  TextItalic,
  AlignTextLeft,
  AlignTextCenter,
  AlignTextRight,
  ThumbsUp,
  DocumentFolder,
  BackgroundColor,
  Fill,
  Undo,
  Redo,
  Save,
  DocAdd,
  Search,
  LocalTwo,
  Pound,
  DownPicture,
  ReadBook
} from '@icon-park/vue'
import searchModal from './searchModal'
import errorModal from './errorModal'
import JEditor from './JEditor2'
import playvideoModal from '@/components/playvideo-modal'
import { checkPermission } from './../utils/hasPermission'
import { Pushpin } from '@icon-park/vue'
import Vue from 'vue'
import { deleteAction, getAction, v1postAction, v1DeleteAction } from '../../../api/manage'
Vue.directive('copy', {
  bind: (el, binding) => {
    el.$copy = () => {
      const textarea = document.createElement('textarea')
      textarea.value = binding.value
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('Copy')
      document.body.removeChild(textarea)
    }
    el.addEventListener('click', el.$copy)
  },
  unbind: el => {
    el.removeEventListener('click', el.$copy)
  }
})
export default {
  props: {
    departName: {
      type: String,
      default: ''
    },
    contentList: {
      type: Array,
      default: () => []
    },
    authList: {
      type: Array,
      default: () => []
    },
    selectTextAreaData: {
      type: Array,
      default: () => []
    },
    textList: {
      type: Array,
      default: () => []
    },
    textTypes: {
      type: Array,
      default: () => []
    },
    selectTextAreaData: {
      type: Array,
      default: () => []
    },
    defualtTextTypeNo: {
      type: String,
      default: '0'
    },
    bottomHeight: {
      type: Number,
      default: 0
    },
    changeAll: {
      type: Boolean,
      default: true
    },
    gloabalBtnLoading: {
      type: Boolean,
      default: false
    },
    isRejectStatus: {
      type: Boolean,
      default: false
    },
    pageListData: {
      type: Array,
      default: () => []
    },
    showImgLeftListPostions: {
      type: Array,
      default: () => []
    },
    showAnnotationList: {
      type: Array,
      default: () => []
    },
    leftAnnotationList: {
      type: Array,
      default: () => []
    },
    rightAnnotationList: {
      type: Array,
      default: () => []
    },
    logList: {
      type: Array,
      default: () => []
    },
    errorDictList: {
      type: Array,
      default: () => []
    },
    revisionList: {
      type: Array,
      default: () => []
    },
    showOptions: {
      type: Object,
      default: () => ({})
    },
    textBatchStyle: {
      type: Object,
      default: () => ({})
    },
    oldTextBatchStyle: {
      type: Object,
      default: () => ({})
    },
    taskDetail: {
      type: Object,
      default: () => ({})
    },
    userInfo: {
      type: Object,
      default: () => ({})
    },
    color: {
      type: String,
      default: '#000000'
    },
    fontFamilyList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    JEditor,
    errorModal,
    Pushpin,
    playvideoModal,
    searchModal,
    DownPicture,
    ReadBook,
    DocumentFolder,
    LocalTwo,
    Search,
    Pound,
    DocAdd,
    Fill,
    ThumbsUp,
    BackgroundColor,
    AlignTextLeft,
    AlignTextCenter,
    AlignTextRight,
    TextRotationNone,
    TextRotationDown,
    TextBold,
    TextItalic,
    Undo,
    Redo,
    Save
  },
  data() {
    return {
      strokeWidths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40],
      changeSignValue: 0,
      showAnnotationOpenKey: [],
      checkedToStages: ['翻译环节', '制作环节'],
      imagePlayWidth: '1000px',
      showSeachIndex: 0,
      postValue:'',
      fontSizes: [
        {
          label: 4,
          value: 17
        },
        {
          label: 5,
          value: 21
        },
        {
          label: 6,
          value: 25
        },
        {
          label: 7,
          value: 29
        },
        {
          label: 7.5,
          value: 31
        },
        {
          label: 8,
          value: 33
        },
        {
          label: 8.5,
          value: 35
        },
        {
          label: 9,
          value: 37
        },
        {
          label: 9.5,
          value: 39
        },
        {
          label: 10,
          value: 41
        },
        {
          label: 11,
          value: 45
        },
        {
          label: 12,
          value: 49
        },
        {
          label: 14,
          value: 57
        },
        {
          label: 18,
          value: 73
        },
        {
          label: 24,
          value: 97
        },
        {
          label: 30,
          value: 121
        },
        {
          label: 36,
          value: 145
        },
        {
          label: 48,
          value: 197
        },
        {
          label: 60,
          value: 241
        },
        {
          label: 72,
          value: 289
        }
      ],
      lineHeight: [
        {
          label: '10%',
          value: 0.1
        },
        {
          label: '20%',
          value: 0.2
        },
        {
          label: '30%',
          value: 0.3
        },
        {
          label: '40%',
          value: 0.4
        },
        {
          label: '50%',
          value: 0.5
        },
        {
          label: '60%',
          value: 0.6
        },
        {
          label: '70%',
          value: 0.7
        },
        {
          label: '80%',
          value: 0.8
        },
        {
          label: '90%',
          value: 0.9
        },
        {
          label: '100%',
          value: 1
        },
        {
          label: '110%',
          value: 1.1
        },
        {
          label: '115%',
          value: 1.15
        },
        {
          label: '120%',
          value: 1.2
        },
        {
          label: '130%',
          value: 1.3
        },
        {
          label: '150%',
          value: 1.5
        },
        {
          label: '200%',
          value: 2
        }
      ],

      scaleX: [
        {
          label: '10%',
          value: 0.1
        },
        {
          label: '20%',
          value: 0.2
        },
        {
          label: '30%',
          value: 0.3
        },
        {
          label: '40%',
          value: 0.4
        },
        {
          label: '50%',
          value: 0.5
        },
        {
          label: '60%',
          value: 0.6
        },
        {
          label: '70%',
          value: 0.7
        },
        {
          label: '80%',
          value: 0.8
        },
        {
          label: '90%',
          value: 0.9
        },
        {
          label: '100%',
          value: 1
        },
        {
          label: '110%',
          value: 1.1
        },
        {
          label: '120%',
          value: 1.2
        },
        {
          label: '130%',
          value: 1.3
        }
      ],
      scaleY: [
        {
          label: '10%',
          value: 0.1
        },
        {
          label: '20%',
          value: 0.2
        },
        {
          label: '30%',
          value: 0.3
        },
        {
          label: '40%',
          value: 0.4
        },
        {
          label: '50%',
          value: 0.5
        },
        {
          label: '60%',
          value: 0.6
        },
        {
          label: '70%',
          value: 0.7
        },
        {
          label: '80%',
          value: 0.8
        },
        {
          label: '90%',
          value: 0.9
        },
        {
          label: '100%',
          value: 1
        },
        {
          label: '110%',
          value: 1.1
        },
        {
          label: '120%',
          value: 1.2
        },
        {
          label: '130%',
          value: 1.3
        }
      ],
      openEditHistory: 1,
      defualtTextType: '0',
      pushpinColor: '#4a4a4a',
      customStyle: 'background: #fff;border-radius: 4px;margin-bottom: 0px;border: 0;overflow: hidden',
      selectId: null,
      noDrag: false,
      textView: 'originalText',
      selectTextId: null,
      selectRevisitionId: null,
      searchText: '',
      textInputValue: '',
      textDefaultKey: [],
      reDefaultKey: [],
      selectErrorType: 0,
      errorValue: '',
      revisionType: 1,
      isFeedback: 2,
      isReject: 2,
      errors: [0, 1, 2, 3, 4, 5],
      dictList: [],
      rejectItem: null,
      errorOptions: [
        {
          label: '无',
          value: 0
        },
        {
          label: '错翻',
          value: 1
        },
        {
          label: '硬伤',
          value: 2
        },
        {
          label: '软伤',
          value: 3
        },
        {
          label: '驳回',
          value: 4
        },
        {
          label: '反馈',
          value: 5
        }
      ],
      checkAll: true,
      fontColor: '#000000',
      saveColor: 'rgba(0, 0, 0, 0.25)',
      downLoadColor: '#4a4a4a',
      undoColor: '#4a4a4a',
      redoColor: '#4a4a4a',
      color1: '#4a4a4a',
      color2: '#4a4a4a',
      color3: '#4a4a4a',
      color4: '#4a4a4a',
      color5: '#4a4a4a',
      color6: '#4a4a4a',
      color7: '#4a4a4a',
      color8: '#4a4a4a',
      color9: '#4a4a4a',
      color10: '#4a4a4a',
      color11: '#4a4a4a',
      color12: '#4a4a4a',
      color13: '#4a4a4a',
      formData: {
        strokeColor: '',
        fontColor: '',
        textAreaTypeList: []
      }
    }
  },
  watch: {
    textInputValue() {
      if (!this.textInputValue) {
        this.searchText = ''
      }
    },
    defualtTextTypeNo() {
      this.defualtTextType = this.defualtTextTypeNo
      let textBatchStyle = JSON.parse(JSON.stringify(this.textBatchStyle))
      textBatchStyle.textAreaType = this.defualtTextType
      this.$emit('textTypeBatchChangeNo', textBatchStyle)
    },
    defualtTextType() {
      this.$emit('changeDefualtTextTypeNo', this.defualtTextType)
    },
    reDefaultKey() {
      /*       this.$nextTick(() => {
        Array.from(document.getElementsByClassName('show-reject')).forEach(d => {
          Array.from(d.getElementsByTagName('img')).forEach(img => {
            img.addEventListener('click', this.handleImgClick)
          })
        })
      }) */
      this.$emit('showImgHandleImgClick')
    },
    selectRevisitionId() {
      if (this.selectRevisitionId) {
        setTimeout(() => {
          if (
            this.$refs['localInput-revisition-' + this.selectRevisitionId] &&
            this.$refs['localInput-revisition-' + this.selectRevisitionId][0]
          ) {
            this.$refs['localInput-revisition-' + this.selectRevisitionId][0].EditorOnsize()
          }
          if (
            this.$refs['localInput-feedback-' + this.selectRevisitionId] &&
            this.$refs['localInput-feedback-' + this.selectRevisitionId][0]
          ) {
            this.$refs['localInput-feedback-' + this.selectRevisitionId][0].EditorOnsize()
          }
          if (
            this.$refs['localInput-item-' + this.selectRevisitionId] &&
            this.$refs['localInput-item-' + this.selectRevisitionId][0]
          ) {
            this.$refs['localInput-item-' + this.selectRevisitionId][0].EditorOnsize()
          }
        }, 500)
      }
    },
    showAnnotationOpenKey() {
      this.$emit('showImgHandleImgClick')
    }
  },
  computed: {
    backCss() {
      return {
        '--theme-color': this.color
      }
    }
  },
  methods: {
    changeDefualtTextTypeNo() {
      this.defualtTextType = this.defualtTextTypeNo
      let textBatchStyle = JSON.parse(JSON.stringify(this.textBatchStyle))
      textBatchStyle.textAreaType = this.defualtTextType
      this.$emit('textTypeBatchChangeNo', textBatchStyle)
    },
    copyText(text) {
      navigator.clipboard.writeText(text)
    },
    fontFamilyChange(e) {
      this.formData.styleName = this.fontFamilyList.find(item => item.id == e).styleName
    },
    changeVideoWidth(width) {
      this.imagePlayWidth = width
    },
    toNextText() {
      if (this.$refs.searchModal) {
        this.$emit('copyData')
        const data = this.$refs.searchModal[0].getNextText()
        this.$emit('changeSelectList', this.pageListData[data.pageIdx].id + '-' + data.item.id + '-right')
      }
    },
    toPrveText() {
      if (this.$refs.searchModal) {
        this.$emit('copyData')
        const data = this.$refs.searchModal[0].getPrevText()
        this.$emit('changeSelectList', this.pageListData[data.pageIdx].id + '-' + data.item.id + '-right')
      }
    },
    toPrveTextList() {
      let selectTextId = this.selectTextId
      let page = null
      let item = null
      let pageIdx = null
      const data = this.prevText(page, item, pageIdx, 0, selectTextId)
      if (data) {
        this.$emit('copyData')
        data.pageIdx = data.pageIdx - 1
        this.toText(this.pageListData[data.pageIdx], data.item, data.pageIdx)
        this.$emit('changeSelectList', this.pageListData[data.pageIdx].id + '-' + data.item.id + '-right')
        return data
      } else {
        return null
      }
    },
    toNextTextList() {
      let selectTextId = this.selectTextId
      let page = null
      let item = null
      let pageIdx = null
      const data = this.nextText(page, item, pageIdx, 0, selectTextId)
      if (data) {
        this.$emit('copyData')
        data.pageIdx = data.pageIdx - 1
        this.toText(this.pageListData[data.pageIdx], data.item, data.pageIdx)
        this.$emit('changeSelectList', this.pageListData[data.pageIdx].id + '-' + data.item.id + '-right')
        return data
      } else {
        return null
      }
    },
    prevText(page, item, pageIdx, num, selectTextId) {
      this.textList.forEach((p, pIdx) => {
        p.textList.forEach((i, itemIdx) => {
          if (i.id == selectTextId) {
            if (itemIdx > 0) {
              if (!num) {
                item = this.textList[pIdx - num].textList[itemIdx - 1]
              } else {
                item = this.textList[pIdx - num].textList[this.textList[pIdx - num].textList.length - 1]
              }
              page = this.textList[pIdx - num]
              pageIdx = p.sortOrderId
            } else {
              let nextNum = -1
              this.textList.forEach((rpage, rpageIdx) => {
                if (rpageIdx < pIdx && rpage.textList && rpage.textList.length) {
                  nextNum = rpage.sortOrderId
                  item = rpage.textList[rpage.textList.length - 1]
                  page = rpage
                }
              })
              num = nextNum
              pageIdx = num
            }
          }
        })
      })
      if (item) {
        return { page, item, pageIdx }
      } else {
        return null
      }
    },
    nextText(page, item, pageIdx, num, selectTextId) {
      this.textList.forEach((p, pIdx) => {
        p.textList?.forEach((i, itemIdx) => {
          if (i.id == selectTextId) {
            if (itemIdx < this.textList[pIdx + num].textList.length - 1) {
              if (!num) {
                item = this.textList[pIdx + num].textList[itemIdx + 1]
              } else {
                item = this.textList[pIdx + num].textList[itemIdx]
              }
              page = this.textList[pIdx + num]
              pageIdx = p.sortOrderId
            } else {
              let nextNum = -1
              this.textList.forEach((rpage, rpageIdx) => {
                if (rpageIdx > pIdx && rpage.textList.length && nextNum == -1) {
                  nextNum = rpage.sortOrderId
                  item = rpage.textList[0]
                  page = rpage
                }
              })
              num = nextNum
              pageIdx = num
            }
          }
        })
      })
      if (item) {
        return { page, item, pageIdx }
      } else {
        return null
      }
    },
    handleImgClick(e) {
      this.$emit('handleImgClick', e)
    },
    openReList() {
      this.reDefaultKey = this.revisionList.map(page => page.pageId + '')
    },
    closeReList() {
      this.reDefaultKey = []
      this.selectTextId = null
      this.$nextTick(() => {
        this.$emit('defualtIsDragText')
        this.$emit('clearSelect')
      })
    },
    openTextList() {
      this.textDefaultKey = this.textList.map(page => page.pageId)
    },
    closeTextList() {
      this.textDefaultKey = []
      this.selectTextId = null
      this.$nextTick(() => {
        this.$emit('defualtIsDragText')
        this.$emit('clearSelect')
      })
    },
    openAnnotationList() {
      this.leftAnnotationList?.forEach(page => {
        page.annotationList?.forEach(item => {
          item.selected = 1
        })
      })
      this.rightAnnotationList?.forEach(page => {
        page.annotationList?.forEach(item => {
          item.selected = 1
        })
      })
      this.showAnnotationOpenKey = this.showAnnotationList.map((item, index) => index + '')
      this.$forceUpdate()
    },
    closeAnnotationList() {
      this.leftAnnotationList?.forEach(page => {
        page.annotationList?.forEach(item => {
          item.selected = 0
        })
      })
      this.rightAnnotationList?.forEach(page => {
        page.annotationList?.forEach(item => {
          item.selected = 0
        })
      })
      this.showAnnotationOpenKey = []
      this.$forceUpdate()
      this.selectTextId = null
      this.$nextTick(() => {
        this.$emit('defualtIsDragText')
        this.$emit('clearSelect')
      })
    },
    changeAllheight(item) {
      item.allHeight = !item.allHeight
    },
    async changeSign(item) {
      if (!this.changeAll) {
        this.$message.error('当前阶段无法操作')
        return
      }
      const res = await v1postAction('/textarea/reject/solve', {
        rejectIds: item.rejectIds,
        isSolve: item.changeSign == 1 ? 2 : 1
      })
      if (res.code == 200) {
        if (item.changeSign == 1) {
          item.changeSign = 2
        } else {
          item.changeSign = 1
        }
        if (item.changeSign == 2) {
          this.$emit('deleRevision', { id: item.textAreaId })
        }
        this.$emit('getRevisionList')
        this.$emit('getShowimgRevisionList', { id: item.textAreaId })
      } else {
        this.$message.error(res.msg)
      }
    },
    topItem(item) {
      const contentList = JSON.parse(JSON.stringify(this.contentList))
      if (
        contentList.sort((a, b) => {
          return b.zIndex - a.zIndex
        })[0] == 999
      ) {
        item.zIndex++
      } else {
        item.zIndex =
          contentList.sort((a, b) => {
            return b.zIndex - a.zIndex
          })[0].zIndex + 1
      }
    },
    async postRejectValue(rejectItem) {
      if (this.taskDetail.stageName != '校对' && this.taskDetail.stageName != '终审') {
        this.$message.error('当前阶段无法驳回')
        return
      }
      if (!rejectItem.comment) {
        this.$message.error('内容不能为空')
        return
      }
      let params = {
        comment: rejectItem.comment,
        stageName: this.taskDetail.stageName,
        textAreaRejectId: rejectItem.textAreaRejectId,
        userId: this.userInfo.id,
        username: this.userInfo.nickName,
        avatar: this.userInfo.userFile.avatar,
        id: rejectItem.id
      }
      const res = await v1postAction('/textarea/reject_msg/edit', params)
      if (res.code == 200) {
        this.$message.success('编辑成功')
        this.$emit('getRevisionList')
        this.$emit('getShowimgRevisionList', { id: this.rejectItem.textAreaId })
      } else {
        this.$message.error(res.msg)
      }
    },
    closeStyle(item) {
      item.visible = false
      this.formData = {
        strokeColor: '',
        fontColor: '',
        textAreaTypeList: []
      }
    },
    saveStyle(item) {
      if (this.formData.fontFamilyId) {
        this.formData.fontFamily = this.fontFamilyList.find(f => f.id == this.formData.fontFamilyId).fontFamily
      }
      this.$emit('changeGloabalBtnLoading')
      this.$emit('saveGloabal', { formData: this.formData, item })
    },
    textAlignChange(type) {
      this.formData.textAlign = type
    },
    fontChange(e) {
      if (e > 0 == false) return
      if (e.indexOf('.') > -1 && e.split('.')[1].length > 1) return
      if (this.fontSizes.every(item => item.label != e)) {
        this.fontSizes.push({ value: e * 4 + 1, label: e })
      }
    },
    isInclineChange() {
      if (this.formData.isIncline) {
        this.formData.isIncline = 0
      } else {
        this.formData.isIncline = 1
      }
    },
    boldChange() {
      if (this.formData.isBold) {
        this.formData.isBold = 0
      } else {
        this.formData.isBold = 1
      }
    },
    textLayoutChange(type) {
      this.formData.textLayout = type
    },
    getTextStyle() {
      let text = this.formData
      if (this.fontFamilyList.filter(item => item.id == this.formData.fontFamilyId).length) {
        text.fontFamily = this.fontFamilyList.filter(item => item.id == this.formData.fontFamilyId)[0].fontFamily
      }
      let style =
        `font-size:${text.fontSize}px;` +
        `text-align:${text.textAlign}px;` +
        `color:${text.fontColor};` +
        `font-family:${text.fontFamily ? 'fontCssView' + text.fontFamily : ''};` +
        `font-weight:${text.isBold ? 'bold' : ''};` +
        `font-style:${text.isIncline ? 'italic' : ''};` +
        `transform:scale(${text.textWidth},${text.textHeight});position:relative;`
      return style
    },
    colorClick(type) {
      this.$refs['colorPicker' + type][0].openPanel()
    },
    getPageList() {
      this.$emit('searchTextList', {})
      this.searchText = ''
    },
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    topCenterMouseout() {
      this.$emit('topCenterMouseout')
    },
    changeIsDele(value) {
      if (value) {
        this.$emit('topCenterMouseover')
      } else {
        this.$emit('topCenterMouseout')
      }
    },
    getText(text) {
      return text.replaceAll('<br>', '\n')
    },
    async errorEnter(e, item, page) {
      //禁止回车的默认换行
      if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea) //获取光标位置
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          item.rejectValue = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          item.rejectValue = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
        //增加换行符
      } else if (e.keyCode == 13) {
        e.preventDefault()
        if (!item.rejectValue) {
          this.$message.error('内容不能为空')
          return
        }
        /*         let params = {
          comment: item.rejectValue,
          stageName: this.taskDetail.stageName,
          textAreaRejectId: item.rejectComments[0]?item.rejectComments[0].textAreaRejectId:null,
          replyId: item.rejectComments[item.rejectComments.length - 1]?item.rejectComments[item.rejectComments.length - 1].id:null,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
        const res = await v1postAction('/textarea/reject_msg/edit', params)
        if (res.code == 200) {
          this.$message.success('回复成功')
          this.$emit('getRevisionList')
        } else {
          this.$message.error(res.msg)
        } */
        let params = {
          productionId: this.taskDetail.productionId,
          chapterId: this.taskDetail.chapterId,
          pageId: page.pageId,
          textAreaId: item.textAreaId,
          taskId: this.taskDetail.taskId,
          comment: item.rejectValue,
          rejectType: this.taskDetail.stageName == '校对' ? 1 : 2,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
        const res = await v1postAction('/textarea/reject', params)
        if (res.code == 1) {
          this.errorValue = ''
          /* this.selectErrorType = 0 */
          item.isReject = true
          this.$message.success('驳回成功')
          this.$forceUpdate()
          this.$emit('getRevisionList')
          this.$emit('getShowimgRevisionList', { id: item.textAreaId })
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    async feedBackEnter(e, item) {
      /*       if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea)
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          item.feedBackValue = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          item.feedBackValue = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
      } else if (e.keyCode == 13) {
        e.preventDefault()
        if (!item.feedBackValue) {
          this.$message.error('内容不能为空')
          return
        }
        let params = {
          feedback: item.feedBackValue,
          textAreaId: item.textAreaId,
          stageName: this.revisionType == 1 ? '校对' : '终审',
          replyId: null,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
        if (item.feedbacks[item.feedbacks.length - 1]) {
          params.replyId = item.feedbacks[item.feedbacks.length - 1].id
        }
        const res = await v1postAction('/textarea/feedback/edit', params)
        if (res.code == 200) {
          this.$message.success('反馈成功')
          this.$emit('getRevisionList')
          this.$emit('getShowimgRevisionList', { id: item.textAreaId })
        } else {
          this.$message.error(res.msg)
        }
      } */
      if (!item.feedBackValue) {
        this.$message.error('内容不能为空')
        return
      }
      let params = {
        feedback: item.feedBackValue,
        textAreaId: item.textAreaId,
        stageName: this.revisionType == 1 ? '校对' : '终审',
        replyId: null,
        userId: this.userInfo.id,
        username: this.userInfo.nickName,
        avatar: this.userInfo.userFile.avatar
      }
      if (item.feedbacks[item.feedbacks.length - 1]) {
        params.replyId = item.feedbacks[item.feedbacks.length - 1].id
      }
      const res = await v1postAction('/textarea/feedback/edit', params)
      if (res.code == 200) {
        this.$message.success('反馈成功')
        this.$emit('getRevisionList')
        this.$emit('getShowimgRevisionList', { id: item.textAreaId })
      } else {
        this.$message.error(res.msg)
      }
    },
    async feedbackItemEditEnter(e, feedbackItem, item) {
      /*       if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea) 
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          feedbackItem.feedback = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          feedbackItem.feedback = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
      } else if (e.keyCode == 13) {
        e.preventDefault()
        if (!feedbackItem.feedback) {
          this.$message.error('内容不能为空')
          return
        }
        let params = {
          feedback: feedbackItem.feedback,
          textAreaId: item.textAreaId,
          stageName: this.taskDetail.stageName,
          id: feedbackItem.id,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar
        }
        const res = await v1postAction('/textarea/feedback/edit', params)
        if (res.code == 200) {
          this.$message.success('编辑成功')
          this.$emit('getRevisionList')
          this.$emit('getShowimgRevisionList', { id: item.textAreaId })
        } else {
          this.$message.error(res.msg)
        }
      } */
      if (!feedbackItem.feedback) {
        this.$message.error('内容不能为空')
        return
      }
      let params = {
        feedback: feedbackItem.feedback,
        textAreaId: item.textAreaId,
        stageName: this.taskDetail.stageName,
        id: feedbackItem.id,
        userId: this.userInfo.id,
        username: this.userInfo.nickName,
        avatar: this.userInfo.userFile.avatar
      }
      const res = await v1postAction('/textarea/feedback/edit', params)
      if (res.code == 200) {
        this.$message.success('编辑成功')
        this.$emit('getRevisionList')
        this.$emit('getShowimgRevisionList', { id: item.textAreaId })
      } else {
        this.$message.error(res.msg)
      }
    },
    getTextAreaNum(type) {
      let num = 0
      this.textList.forEach(page => {
        num = page.textAreaCount + num
      })

      return num
    },
    savePageList() {
      this.$emit('copyData')
      this.$emit('savePageList')
    },
    copyMessage() {
      this.$message.success('复制成功')
    },
    getTextNum(type) {
      let num = 0
      if (type == 'originalText') {
        this.textList.forEach(page => {
          num = page.originWordCount + num
        })
      } else {
        this.textList.forEach(page => {
          num = page.translationWordCount + num
        })
      }
      return num
    },
    getSearchList() {},
    getPositionForTextArea(dom) {
      //获取输入框内光标位置
      let CaretPos = {
        start: 0,
        end: 0
      }
      if (dom.selectionStart) {
        // Firefox support
        CaretPos.start = dom.selectionStart
      }
      if (dom.selectionEnd) {
        CaretPos.end = dom.selectionEnd
      }
      return CaretPos
    },
    async rejectEnter(e, rejectItem, item) {
      //禁止回车的默认换行
      if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea) //获取光标位置
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          rejectItem.comment = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          rejectItem.comment = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
        //增加换行符
      } else if (e.keyCode == 13) {
        e.preventDefault()
        if (this.taskDetail.stageName != '校对' && this.taskDetail.stageName != '终审') {
          this.$message.error('当前阶段无法驳回')
          return
        }
        if (!rejectItem.comment) {
          this.$message.error('内容不能为空')
          return
        }
        let params = {
          comment: rejectItem.comment,
          stageName: this.taskDetail.stageName,
          textAreaRejectId: rejectItem.textAreaRejectId,
          userId: this.userInfo.id,
          username: this.userInfo.nickName,
          avatar: this.userInfo.userFile.avatar,
          id: rejectItem.id
        }
        const res = await v1postAction('/textarea/reject_msg/edit', params)
        if (res.code == 200) {
          this.$message.success('编辑成功')
          this.$emit('getRevisionList')
          this.$emit('getShowimgRevisionList', { id: item.textAreaId })
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    cancelErrorEdit(item) {
      item.editble = 0
      this.$emit('getRevisionList')
      this.$forceUpdate()
    },
    changeSelectList(id) {
      this.$emit('copyData')
      this.$emit('changeSelectList', id)
    },
    textDefaultKeyChange(e) {
      if (
        !this.textDefaultKey.length ||
        !this.reDefaultKey.length ||
        (this.$refs.searchModal && this.$refs.searchModal[0] && !this.$refs.searchModal[0].textDefaultKey.length)
      ) {
        this.selectRevisitionId = null
        this.selectTextId = null
        if (this.$refs.searchModal && this.$refs.searchModal[0]) {
          this.$refs.searchModal[0].selectTextId = null
        }
        this.$nextTick(() => {
          this.$emit('defualtIsDragText')
          this.$emit('clearSelect')
        })
      }
      if (this.reDefaultKey && this.reDefaultKey.length) {
        let modifyHistoryList = []
        this.reDefaultKey.forEach(re => {
          this.revisionList.forEach(page => {
            if (page.id == re) {
              modifyHistoryList = page.modifyHistoryList || []
            }
          })
        })
        if (modifyHistoryList.every(textarea => textarea.textAreaId != this.selectRevisitionId)) {
          this.selectRevisitionId = null
          this.selectTextId = null
          if (this.$refs.searchModal && this.$refs.searchModal[0]) {
            this.$refs.searchModal[0].selectTextId = null
          }
          this.$nextTick(() => {
            this.$emit('defualtIsDragText')
            this.$emit('clearSelect')
          })
        }
      }
    },
    getSearchKey(selectTextAreaData) {
      if (this.$refs.searchModal && this.$refs.searchModal[0]) {
        this.$refs.searchModal[0].selectTextId = selectTextAreaData[0].id
        if (
          this.$refs.searchModal[0].textDefaultKey.every(
            key => key != selectTextAreaData[0].pageId + '-' + this.taskDetail.chapterId
          )
        ) {
          this.$refs.searchModal[0].textDefaultKey.push(selectTextAreaData[0].pageId + '-' + this.taskDetail.chapterId)
        }
        this.$refs.searchModal[0].selectTextChangeId = selectTextAreaData[0].id
        if (this.$refs.searchModal[0].textChangeDefaultKey.every(key => key != selectTextAreaData[0].pageId + '')) {
          this.$refs.searchModal[0].textChangeDefaultKey.push(selectTextAreaData[0].pageId + '')
        }
      }
    },
    async changeError(item, page) {
      if (!item.remark) return
      let params = {
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId,
        injuryType: item.injuryType,
        pageId: page.pageId,
        remark: item.remark,
        targetType: item.targetType,
        textAreaId: item.textArea ? item.textArea.textAreaId : item.textAreaId,
        taskId: this.taskDetail.taskId,
        id: item.injuryId
      }
      const res = await v1postAction('/injury/edit_record', params)
      if (res.code == 1) {
        item.editble = 0
        this.$message.success('编辑成功')
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg)
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async changeInjuryType(item, type) {
      item.injuryType = type
      item.remark = ''
      let code = !item.injuryType
        ? ''
        : item.injuryType == 1
        ? 'hy-rs'
        : item.injuryType == 2
        ? 'hy-ys'
        : item.injuryType == 3
        ? 'hy-cf'
        : ''
      if (this.departName.indexOf('日语部') > -1) {
        code = !item.injuryType
          ? ''
          : item.injuryType == 1
          ? 'ry-rs'
          : item.injuryType == 2
          ? 'ry-ys'
          : item.injuryType == 3
          ? 'ry-cf'
          : ''
        if (this.taskDetail.stageName == '校对' || this.taskDetail.stageName == '终审') {
          code = !item.injuryType
            ? ''
            : item.injuryType == 1
            ? 'jd_fy-zs_jd_rs'
            : item.injuryType == 2
            ? 'jd_fy-zs_jd_ys'
            : item.injuryType == 3
            ? 'jd_fy-zs_jd_cf'
            : ''
        }
      }
      let id = this.errorDictList.find(item => item.dictCode == code).id
      const res = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: id })
      if (res.code == 200) {
        this.dictList = res.data.records.filter(r => r.status)
      }
    },
    changeRevisionList() {
      this.$emit('getRevisionList')
      /*       if(this.selectTextAreaData.length){
        this.$emit('getShowimgRevisionList', { id: this.selectTextAreaData[0].id })
      } */
    },
    textTypeChange(e) {
      let textBatchStyle = JSON.parse(JSON.stringify(this.textBatchStyle))
      textBatchStyle.textAreaType = e.target.value
      this.$emit('textTypeBatchChange', textBatchStyle)
    },
    textSearch(e) {
      this.$emit('searchTextList', { type: this.textView == 'originalText' ? 1 : 2, keyword: e })
    },
    toText(page, item, pageIdx) {
      this.$emit('copyData')
      this.selectTextId = item.id
      let p = this.pageListData[pageIdx].textAreas.find(i => i.id == item.id)
      this.$emit('changeSelectList', page.pageId + '-' + item.id + '-left')
      /* this.$emit('changeYahoo',p.showText,page.id,p.id,p) */
      let num = this.showImgLeftListPostions[pageIdx].width / this.pageListData[pageIdx].width - 0
      let y = (p.textAreaRegion.y * num).toFixed(0) - 0
      document.getElementsByClassName('show-img-bottom')[0].scrollTop =
        this.showImgLeftListPostions[pageIdx].top - this.showImgLeftListPostions[0].top + y - 30
    },
    async toRevisiton(page, item, pageSortOrderId) {
      this.$emit('copyData')
      let pageIdx = pageSortOrderId - 1
      this.selectRevisitionId = item.textAreaId
      this.reDefaultKey = [...this.reDefaultKey, page.pageId + '']
      this.reDefaultKey = [...new Set(this.reDefaultKey)]
      this.$emit('changeSelectList', page.pageId + '-' + item.textAreaId + '-left')
      let num = this.showImgLeftListPostions[pageIdx].width / this.pageListData[pageIdx].width - 0
      let y = (item.textAreaRegion.y * num).toFixed(0) - 0
      document.getElementsByClassName('show-img-bottom')[0].scrollTop =
        this.showImgLeftListPostions[pageIdx].top - this.showImgLeftListPostions[0].top + y - 30
      if (this.$refs[item.textAreaId + 'revision'] && this.$refs[item.textAreaId + 'revision'][0].offsetTop >= 55) {
        document.getElementsByClassName('text-list-bottom')[0].scrollTop =
          this.$refs[item.textAreaId + 'revision'][0].offsetTop - 55
      }
      let code = !item.injuryType
        ? ''
        : item.injuryType == 1
        ? 'hy-rs'
        : item.injuryType == 2
        ? 'hy-ys'
        : item.injuryType == 3
        ? 'hy-cf'
        : ''
      if (this.departName.indexOf('日语部') > -1) {
        code = !item.injuryType
          ? ''
          : item.injuryType == 1
          ? 'ry-rs'
          : item.injuryType == 2
          ? 'ry-ys'
          : item.injuryType == 3
          ? 'ry-cf'
          : ''
        if (this.taskDetail.stageName == '校对' || this.taskDetail.stageName == '终审') {
          code = !item.injuryType
            ? ''
            : item.injuryType == 1
            ? 'jd_fy-zs_jd_rs'
            : item.injuryType == 2
            ? 'jd_fy-zs_jd_ys'
            : item.injuryType == 3
            ? 'jd_fy-zs_jd_cf'
            : ''
        }
      }
      if (!code) return
      let id = this.errorDictList.find(item => item.dictCode == code).id
      const res = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: id })
      if (res.code == 200) {
        this.dictList = res.data.records.filter(r => r.status)
      }
    },
    editorOnsize() {
      const timeOut = setTimeout(()=>{
        if(this.$refs['localInput-local-send']&&this.$refs['localInput-local-send'][0]){
          this.$refs['localInput-local-send'][0].EditorOnsize()
          clearTimeout(timeOut)
      }
      this.$forceUpdate()
      },100)
    },
    editLocalContent(i, item) {
      i.editble = 1
      this.noDrag = true
      if (item) {
        this.$nextTick(() => {
          this.$refs['localInput-local-' + item.id][0].EditorOnsize()
        })
      }
      this.$forceUpdate()
    },
    editLocalContentReject(i, item) {
      this.rejectItem = item
      this.$refs.errorModal.edit(i)
    },
    checkPermission(permission) {
      return checkPermission(permission, this.authList)
    },
    deletLocal(item,i) {
      const dom = document.getElementById('local-list-item-' + item.id)
      let imgs = null
      if (Array.from(dom.getElementsByTagName('IMG')) && Array.from(dom.getElementsByTagName('IMG')).length) {
        imgs = Array.from(dom.getElementsByTagName('IMG')).map(e => e.src)
      }
      let that = this
      this.$confirm({
        title: '确认删除?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        async onOk() {
          const res = await deleteAction('/annotation/comment/delete/' + i.id)
          if (res.code == 200) {
            that.$message.success('删除成功')
            that.$emit('closeLocal', imgs)
            that.$emit('getLocalList')
          } else {
            that.$message.error(res.msg)
          }
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    deletRevisition(item) {
      let that = this
      this.$confirm({
        title: '确认删除?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        async onOk() {
          const res = await v1postAction('/injury/delete_record', { id: item.injuryId })
          if (res.code == 1) {
            that.$message.success('删除成功')
            that.$emit('getRevisionList')
            that.$emit('getShowimgRevisionList', { id: item.textAreaId })
            that.$forceUpdate()
          } else {
            that.$message.error(res.msg)
          }
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    deletReject(item, i) {
      let that = this
      this.$confirm({
        title: '确认删除?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        async onOk() {
          const res = await v1DeleteAction('/textarea/reject_msg/delete/' + item.id)
          if (res.code == 200) {
            that.$message.success('删除成功')
            that.$emit('getRevisionList')
            that.$emit('getShowimgRevisionList', { id: item.id })
            that.$emit('deleRevision', { id: i.textAreaId })
            that.$forceUpdate()
          } else {
            that.$message.error(res.msg)
          }
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    deletFeedback(item) {
      let that = this
      this.$confirm({
        title: '确认删除?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        async onOk() {
          const res = await v1DeleteAction('/textarea/feedback/delete/' + item.id)
          if (res.code == 200) {
            that.$message.success('删除成功')
            that.$emit('getRevisionList')
            that.$emit('getShowimgRevisionList', { id: item.textAreaId })
            that.$forceUpdate()
          } else {
            that.$message.error(res.msg)
          }
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    pushLoacal(item){
      item.postValue=this.postValue
      this.postValue=""
      this.$emit('pushLoacal',item)
    },
    localEnter(e, item, i) {
      /*       if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea)
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          i.content = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          i.content = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
      } else if (e.keyCode == 13) {
        e.preventDefault()
        let localValue = i.content
        if (!localValue) {
          this.$message.error('请输入批注内容')
          return
        }
        let type = item.around
        i.editble = 0
        this.$emit('saveLocal', { type, item, value: i.content })
        this.$forceUpdate()
      } */
      let localValue = i.content
      if (!localValue) {
        this.$message.error('请输入批注内容')
        return
      }
      let type = item.around
      i.editble = 0
      this.$emit('saveLocal', { type, item, value: i.content,localId:i.id })
      this.$forceUpdate()
    },
    toLocal(item) {
      this.selectId = item.id
      let idx = this.pageListData.findIndex(p => p.id == item.pageId)
      let num = this.showImgLeftListPostions[idx].width / this.pageListData[idx].width - 0
      if (item.around == 'left') {
        this.$emit('changeAnnotationListSelect', { id: item.id, type: 'left' })
      } else {
        this.$emit('changeAnnotationListSelect', { id: item.id, type: 'right' })
      }
      /* let node = document.getElementById('local-'+item.id) */
      document.getElementsByClassName('show-img-bottom')[0].scrollTop =
        (item.position.y * num).toFixed(0) - 0 + (this.showImgLeftListPostions[idx].top - 153) - 100
      /* console.log(node.getClientRects()[0]) */
      this.$nextTick(()=>{
        if(this.$refs['localInput-local-send']&&this.$refs['localInput-local-send'][0]){
          this.$refs['localInput-local-send'][0].EditorOnsize()
        }
      })
    },
    mousedown(e, item) {
      if (this.noDrag || item.isPushpin) return
      this.$emit('changeContentDarg', item)
    },
    closeContentList(item) {
      this.noDrag = false
/*       this.selectId = null
      this.selectTextId = null
      this.selectRevisitionId = null
      this.searchText = ''
      this.textInputValue = ''
      this.showAnnotationOpenKey = []
      this.leftAnnotationList?.forEach(page => {
        page.annotationList?.forEach(item => {
          item.selected = 0
        })
      })
      this.rightAnnotationList?.forEach(page => {
        page.annotationList?.forEach(item => {
          item.selected = 0
        })
      }) */
      if (item.id == 7) {
        if (this.$refs.searchModal[0]) this.$refs.searchModal[0].handleCancel()
      }
      this.$emit('closeContentDarg', item)
    }
  }
}
</script>

<style scoped lang="less">
.local-show {
  position: relative;
}
/deep/ .show-reject {
  img {
    max-width: 200px !important;
  }
  p {
    margin: 0;
  }
}
.open-all-annotation-list {
  position: absolute;
  line-height: 22px;
  right: 16px;
  top: 12px;
  z-index: 1;
}
.change-sign-over {
  color: var(--theme-color);
}
.change-sign:hover {
  cursor: pointer;
  transition: all 0.3s;
  color: var(--theme-color);
}
.space-text {
  white-space: pre-line;
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
}
/deep/ .colorBtn {
  background: none !important;
}
.global-form {
  padding-left: 25px;
  padding-right: 25px;
  width: 400px;
  .global-item {
    padding-top: 15px;
    display: flex;
    .anticon {
      font-size: 18px;
    }
    .global-item-option {
      width: 30px;
      height: 30px;
      padding: 5px;
      border-radius: 4px;
      display: flex;
      font-size: 14px;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      transition: 0.3s all;
      &:hover {
        background-color: rgba(109, 109, 109, 0.1) !important;
        color: var(--theme-color);
      }
    }
    .global-item-title {
      font-weight: bold;
    }
    .global-item-show {
      background-color: #f0f2f5;
      display: flex;
      width: 100%;
      height: 126px;
      justify-content: center;
      align-items: center;
      padding: 12px;
      overflow: auto;
    }
  }
  .color-change {
    width: 12px;
    height: 30px;
    background: rgba(0, 0, 0, 0);
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    z-index: 1;
    cursor: pointer;
    .color-change-text {
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
    }
    .color-change-back {
      height: 18px;
    }
    .color-change-stroke-text {
      font-size: 17px;
      font-weight: bold;
      color: #fff;
      &:hover {
        -webkit-text-stroke: 1px var(--theme-color) !important;
      }
    }
    .color-change-bottom {
      height: 3px;
    }
    .color-change-bottom-stroke {
      height: 3px;
      border: 1px solid rgba(0, 0, 0, 0.25);
    }
    .color-change-bottom-back {
      width: 80%;
      left: 5px;
      position: relative;
      border: 1px solid rgba(0, 0, 0, 0.25);
      top: 1px;
      height: 3px;
    }
  }
  .stroke-span-child {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
  }
  .stroke-span-child::after {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    left: 0;
    top: 0;
    -webkit-text-stroke: 0;
  }
}
/deep/ .global-form .ant-select-selection:hover {
  background-color: rgba(109, 109, 109, 0.1) !important;
}
.log-list {
  padding-top: 20px;
  display: flex;
  width: 400px;
  justify-content: center;
  overflow-y: auto;
  max-height: 500px;
  .ant-timeline-item {
    min-height: 60px;
  }
}
/deep/.log-list .ant-timeline-item-content {
  position: absolute !important;
  left: -182px;
}
.pushpin-icon {
  cursor: pointer;
}
.log-list-item {
  display: flex;
  width: 350px;
  .log-list-item-left {
    width: 200px;
    display: flex;
    .log-list-item-left-left,
    .log-list-item-left-right {
      height: 40px;
    }
    .log-list-item-left-left {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
    .log-list-item-left-right {
      > div {
        line-height: 20px;
        font-size: 12px;
      }
    }
  }
  .log-list-item-right {
    width: 150px;
    line-height: 40px;
  }
}
.text-type {
  width: 400px;
  padding: 10px 15px;
  padding-bottom: 13px;
  .ant-radio-wrapper {
    width: 115px;
  }
}
.text-list-show {
  width: 400px;
  padding: 15px;

  .text-list-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text-list-radio {
      display: inline-block;
      background-color: #f7f7f7;
      border: 1px solid #dfdfdf;
      border-radius: 4px;
      > span {
        display: inline-block;
        padding-top: 4px;
        padding-bottom: 4px;
        transition: all 0.3s;
        font-size: 12px;
        > span {
          padding-left: 10px;
          padding-right: 10px;
        }
        cursor: pointer;
        &:hover {
          color: var(--theme-color);
        }
      }
      .text-list-radio-selected {
        background-color: #fff;
        color: var(--theme-color);
      }
      .text-list-radio1 {
        > span {
          border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
      .text-list-radio2 {
        /*         > span {
          border-right: 1px solid rgba(0, 0, 0, 0.2);
        } */
      }
      .text-list-radio3 {
        > span {
          border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  .text-list-center {
    padding-top: 15px;
    width: 370px;
    .ant-row {
      line-height: 40px;
      .revision-action {
        padding-left: 20px;
      }
    }
  }
  .text-list-bottom {
    overflow-y: auto !important;
    max-height: 400px !important;
    position: relative;
    .local-content-list-show {
      line-height: 24px;
    }
  }
}
/deep/ .revision-action .ant-checkbox-group .ant-checkbox-wrapper {
  line-height: 32px !important;
  margin-right: 12px;
}
/deep/ .m-colorPicker .box.open {
  z-index: 100 !important;
  top: 32px;
}
/deep/ .m-colorPicker .box {
  z-index: 100 !important;
  top: 32px;
}
::-webkit-scrollbar-button {
  height: 0;
}
::-webkit-scrollbar {
  border-radius: 0;
  position: relative;
  width: 3px;
}
::-webkit-scrollbar-track {
  border-radius: 0;
  background: #fff;
}
::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
.show-img-bottom-left {
  margin-right: 3px;
}

/* /deep/ .text-list-show .ant-collapse-content-box {
  overflow-y: auto !important;
  max-height: 400px !important;
  &::-webkit-scrollbar-button {
    height: 0;
  }
  &::-webkit-scrollbar {
    border-radius: 0;
    position: relative;
    width: 0px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: #fff;
  }
  &::-webkit-scrollbar-track-piece {
    border-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #c1c1c1;
  }
  .show-img-bottom-left {
    margin-right: 3px;
  }
} */
/* /deep/ .local-show .ant-collapse-content-box {
  overflow-y: auto !important;
  max-height: 400px !important;
  &::-webkit-scrollbar-button {
    height: 0;
  }
  &::-webkit-scrollbar {
    border-radius: 0;
    position: relative;
    width: 0px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: #fff;
  }
  &::-webkit-scrollbar-track-piece {
    border-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #c1c1c1;
  }
  .show-img-bottom-left {
    margin-right: 3px;
  }
} */
.local-show {
  overflow-y: auto !important;
}
.local-content-list-show-selected {
  background-color: #f7f7f7 !important;
  .anticon-copy {
    display: inline-block;
  }
}
.local-content-list-show {
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 5px;
  &:hover {
    .anticon-copy {
      display: inline-block;
    }
    background-color: #f7f7f7 !important;
  }
}
.local-content-list-num {
  width: 50px;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  > div {
    background-color: black;
    margin-left: 15px;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    border-radius: 50%;
    text-align: center;
  }
}
.local-content-list-num-two {
  width: 50px;
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 40px;
  > div {
    background-color: black;
    margin-left: 15px;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    border-radius: 50%;
    text-align: center;
  }
}
/deep/ .revisition-content {
  .ant-select {
    border: none !important;
    .ant-select-arrow {
      display: none !important;
    }
    .ant-select-selection {
      box-shadow: none !important;
      border: none !important;
    }
  }
  .ant-input {
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
}
.local-content-list {
  max-width: 315px;
  padding: 5px;
  padding-right: 15px;
  flex: 1;
  .local-content-item {
    &:hover {
      .action-span {
        display: inline-block !important;
      }
    }
    .local-content-item-top {
      height: 36px;
      display: flex;
      .local-content-item-top-left {
        width: 20px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
      .local-content-item-top-right {
        font-size: 12px;
        margin-left: 5px;
        width: 100%;
        > div {
          line-height: 18px;
        }
        .action-span {
          display: none;
          transition: all 0.3s;
          &:hover {
            color: var(--theme-color);
          }
        }
        .anticon-more {
          font-size: 16px;
        }
        .action-span-select {
          display: inline-block;
        }
        .local-content-item-top-right-user {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .local-content-item-bottom {
      min-height: 25px;
      font-size: 14px;
      line-height: 25px;
      padding-left: 25px;
      color: black;
      > span {
       /*  white-space: pre-line; */
        display: inline-block;
        width: 100%;
       /*  word-wrap: break-word; */
      }
      .ant-input {
        border: none;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .revisition-content {
    padding-bottom: 5px;
    color: black;
    > div {
      > span {
        line-height: 20px;
      }
    }
    .ocr-show-item-action-top {
      padding-top: 5px;
      .ocr-show-item-action-radio {
        font-size: 14px;
        padding-left: 6px;
        .error-span {
          background-color: #fff;
          position: relative;
          .anticon-check-circle {
            position: absolute;
            top: -2px;
            right: -4px;
            font-size: 10px;
            color: var(--theme-color);
          }
        }
        > span {
          display: inline-block !important;
          width: 25px;
          height: 25px;
          text-align: center;
          cursor: pointer;
          &:hover {
            background-color: #fff;
          }
        }
      }
      .ocr-show-item-action-input {
        padding-top: 10px;
        > .ocr-show-item-action-input-button {
          padding-top: 10px;
          padding-bottom: 10px;
          display: flex;
          justify-content: end;
          .ant-btn {
            font-size: 12px !important;
          }
        }
      }
      /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
    }
    .revisition-content-error {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        .action-span {
          display: inline-block !important;
        }
      }
      .action-span {
        display: none;
        transition: all 0.3s;
        &:hover {
          color: var(--theme-color) !important;
        }
      }
      .anticon-more {
        font-size: 16px;
      }
      .action-span-select {
        display: inline-block;
        &:hover {
          color: var(--theme-color) !important;
        }
      }
    }
  }
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}
.drag-content-list {
  width: 400px;
  min-height: 100px;
  position: absolute;
  box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
  background: #fff;
  z-index: 999;
  border-radius: 5px;
  .drag-content-title {
    font-size: 14px;
    background-color: #ebebeb;
    padding: 2px 12px 2px 12px;
    line-height: 32px;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .anticon-close {
      cursor: pointer;
      &:hover {
        color: var(--theme-color);
      }
    }
    .anticon-column-height {
      cursor: pointer;
      &:hover {
        color: var(--theme-color);
      }
    }
    .anticon-vertical-align-middle {
      cursor: pointer;
      &:hover {
        color: var(--theme-color);
      }
    }
    .anticon-redo {
      cursor: pointer;
      &:hover {
        color: var(--theme-color);
      }
    }
  }
}
.anticon-copy {
  cursor: pointer;
  margin-left: 3px;
  transition: 0.3s color;
  display: none;
  &:hover {
    color: var(--theme-color);
  }
}
</style>
